<template>
  <div class="flex flex-col gap-4 overflow-auto px-8 py-5 w-3/4">
    <div v-if="data.length" class="flex flex-col">
      <el-form
        :model="model"
        :ref="refs.parametersValidation"
        class="flex flex-col gap-4 mb-2"
      >
        <div
          v-for="field in data"
          :key="field.key"
          class="flex justify-between items-center object-parameters__details__additional-fields__items gap-3"
        >
          <div class="flex gap-2">
            <div class="relative">
              <span class="object-parameters__details__label input-label">{{
                $t('new_units.objects.fields.parameters.name')
              }}</span>
              <el-form-item
                :rules="parameterRule"
                :prop="`name_${field.localId}`"
              >
                <el-input v-model="field.name" />
              </el-form-item>
            </div>
            <div class="relative">
              <span class="object-parameters__details__label input-label">{{
                $t('new_units.objects.fields.parameters.value')
              }}</span>
              <el-form-item
                :rules="parameterRule"
                :prop="`value_${field.localId}`"
              >
                <el-input v-model="field.value" />
              </el-form-item>
            </div>
          </div>
          <div
            class="object-parameters__details__additional-fields__switch cursor-pointer bg-lightGrey-200 rounded-full"
          >
            <el-tooltip
              :open-delay="700"
              :content="$t('new_units.objects.fields.parameters.switch.hint')"
              :visible-arrow="false"
              effect="dark"
              placement="bottom"
            >
              <UnitsSwitch
                :value="field.show_in_monitoring"
                @onChange="field.show_in_monitoring = $event"
              />
            </el-tooltip>
          </div>
          <div
            class="object-parameters__details__additional-fields__remove cursor-pointer bg-lightGrey-200 rounded-full"
            @click="$emit('onDelete', field.localId)"
          >
            <DeleteIcon />
          </div>
        </div>
      </el-form>
    </div>
    <div v-else class="self-center">
      <span class="greyToneSecond">{{
        $t('new_units.objects.fields.parameters.no_parameters')
      }}</span>
    </div>
    <UnitsAddButton
      active-tab="parameters"
      class="object-parameters__details__additional-fields__add self-center"
      @click.native="$emit('onAdd')"
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'

import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue'
import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton.vue'

import { refs } from '@/components/unitsNew/helpers/index.js'

import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  }
})

const model = ref({})

const getPropValues = () => {
  props.data.forEach((field) => {
    model.value['name_' + field.localId] = field.name
    model.value['value_' + field.localId] = field.value
  })
}

const validateField = (rule, value, callback) => {
  const type = rule.field.split('_')[0]
  const localId = rule.field.split('_')[1]

  const currentField = props.data.find((item) => item.localId === +localId)

  if (currentField[type]) {
    callback()
  } else {
    callback(new Error())
  }
}

watch(
  () => props.data,
  () => {
    getPropValues()
  }
)

const parameterRule = [
  {
    validator: validateField,
    message: $t('new_units.objects.fields.parameters.errors.wrong_value')
  }
]
</script>
